import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import sendData from '../Api/Data';
import { Link } from 'react-router-dom';
import logo from "../Assets/Images/Mkanak-Logo-small.png";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faCartPlus } from "@fortawesome/free-solid-svg-icons";

const PastOrders = () => {

    const [PastOrders,setPastOrders] = useState([]);

    useEffect(()=>{
        getPastOrders();
    },[])

    const getPastOrders = () => {
        const token = Cookies.get('tokenv2');
        const decodedToken = jwtDecode(token);
        const uid = decodedToken.uid;

        sendData(`Order/${uid}`, {}, "Get").then((res) => {
          if (res.status !== 200) {
            setPastOrders([]);
          } else {
            setPastOrders(res.data);
          }
        });
    };

  return (
    <div className='min-h-screen p-4 bg-gray-100'>
      <div className="flex items-center justify-between mb-3">
        <img src={logo} alt="Logo" className="w-32 h-auto" />
        <div className="flex items-center relative">
        </div>
      </div>
      {
        PastOrders.length?
        <div>
            {
                PastOrders.map((order,index) => (
                    <OrderItem
                      key={index}
                      order={order}
                    />
                ))
            }
        </div>
        :
        <div className=" absolute top-[450px] left-0 flex w-full justify-center items-center"> <p className="text-2xl text-gray-500 font-extrabold  "> لم تطلب اوردرات الي الان 🛒</p> </div>
      }
    </div>
  )
}


const OrderItem = ({ order }) => {
    return (
      <AnimatePresence>
        <motion.div
          className="flex items-center border p-4 rounded-lg w-full md:w-3/4 lg:w-1/2"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.5 }}
          whileHover={{ scale: 1.02, boxShadow: "0px 10px 30px rgba(0,0,0,0.1)" }}
        >
          <div className="flex-1 mr-4">
            <h2 className="font-bold text-lg">{order?.userName } </h2>
            <p className="text-gray-500"> {order?.state +" " + order?.city +" " + order?.street } </p>
            {order?.orderItems?.map((orderItem)=>{
                return(
                    <>
                        <p className="text-gray-700"> {orderItem?.book?.title +" : "+ orderItem?.quantity } </p>
                    </>
                )
            })}
          </div>
          <motion.button
            whileTap={{ scale: 0.9 }}
            className="mr-4"
          >
            <p className="text-basic text-lg" >{order?.totalPrice} جنيه</p>
            {order.isActive && <p className={`${order?.paymentStatus == "successful"?" text-green-500":" text-red-500"}`}> {order?.paymentStatus == "successful" ? "تم الدفع ":"لم يتم الدفع "} </p>}
            {order.isActive && (order?.paymentStatus == "successful") && <p className={`${order.isDerived?" text-green-500":" text-blue-500"}`} > {order.isDerived?"تم شحن الطلب ":"جاري شحن الطلب "} </p>}
            {!(order.isActive || order.isDerived  ) && <p className=" text-gray-500" > {"تم ألغاء الطلب "} </p>}
            {((!order.isActive) && order.isDerived  ) && <p className=" text-green-500" > {"تم تسليم الطلب"} </p>}
          </motion.button>
        </motion.div>
      </AnimatePresence>
    );
  };


export default PastOrders
