import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdShoppingCart } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../../Assets/Images/Mkanak-Logo-small.png";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { isAuthenticated } from "../../utils/auth";
import { FaSignInAlt } from "react-icons/fa";
import getData from "../../Api/Data";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import Toast from "./Toast";
import { motion, useAnimation } from "framer-motion";
import { MdOutlineShoppingCart } from "react-icons/md";
import useInView from "../useInView";
import Modal from "../Modal";

const BookDetail = ({ currentBook, setCurrentBook, setCartItems, cartNumber, allBooks }) => {
  const [loaderbtn, setloaderbtn] = useState(false);
  const [sameYearBooks, setSameYearBooks] = useState("");
  const [sameYearMalazem, setSameYearMalazem] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [prevCartNumber, setPrevCartNumber] = useState(cartNumber);
  const [showModal, setsShowModal] = useState(false);
  const [loading, setsLoading] = useState(false);

  const cartControls = useAnimation();
  const navigate = useNavigate();

  const { id } = useParams();

  const bookurl = "https://mkanaak-001-site4.htempurl.com/" + currentBook?.image?.replace(/\\/g, "/");

  useEffect(() => {
    if (cartNumber !== prevCartNumber) {
      cartControls.start({
        scale: [1, 1.2, 1],
        transition: { duration: 0.5, ease: "easeInOut" },
      });
      setPrevCartNumber(cartNumber);
    }
  }, [cartNumber, prevCartNumber, cartControls]);

  useEffect(() => {
    filterBooks();
    window.scrollTo({
      top: 0
    });
  }, [currentBook])

  useEffect(() => {
    if (!currentBook ) {
      setsLoading(true);
      getBook();
    }
  }, [allBooks])

  const getBook = () => {
    if (allBooks?.length) {
      const data = allBooks.find(book => book.bookId == id);
      setCurrentBook(data);
      setsLoading(false);
    }
  }

  const handleLikeClick = () => {
    const auth = isAuthenticated();
    if (auth) {
      if (!loaderbtn) {
        setloaderbtn(true);
        if (currentBook?.favourite) {
          removeFavourite();
        } else {
          addToFavourite();
        }
      }
    } else {
      setsShowModal(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const addToFavourite = () => {
    const token = Cookies.get("tokenv2");
    const decodedToken = jwtDecode(token);
    const uid = decodedToken.uid;
    getData(`FavoriteBooks/${uid}/add/${currentBook.bookId}`, {}, "POST").then(
      (res) => {
        if (res.status !== 200) {
          console.log("like failed");
        } else {
          setCurrentBook({ ...currentBook, favourite: true });
        }
        setloaderbtn(false);
      }
    );
  };

  const removeFavourite = () => {
    const token = Cookies.get("tokenv2");
    const decodedToken = jwtDecode(token);
    const uid = decodedToken.uid;
    getData(
      `FavoriteBooks/${uid}/remove/${currentBook.bookId}`,
      {},
      "DELETE"
    ).then((res) => {
      if (res.status !== 200) {
        console.log("remove failed");
      } else {
        setCurrentBook({ ...currentBook, favourite: false });
      }
      setloaderbtn(false);
    });
  };

  const handleAddToCart = () => {
    const auth = isAuthenticated();

    if (auth) {
      if (currentBook.quantityAvailable >0){
      setCartItems((prevItems) => {
        const isBookInCart = prevItems.some(
          (item) => item.bookId === currentBook.bookId
        );

        if (!isBookInCart) {
          return [...prevItems, currentBook];
        }

        return prevItems;
      });

      setShowToast(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }else {
      alert("نفذت الكمية ");
    }
    } else {
      setsShowModal(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

  };

  const handleSelectBook = (book) => {
    setCurrentBook(book);
    navigate(`/bookdetails/${book.bookId}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const filterBooks = () => {
    if (allBooks) {

      // const newBooks = allBooks?.filter(book =>
      //   (book.categoryId === "") &&
      //   (book.levelId === "") &&
      //   (book.authorId === "") &&
      //   (book.subjectId === "") &&
      //   (book.educationStageId === "")
      // );

      const newBooks = allBooks?.filter(book =>
        (book.levelId === currentBook.levelId) &&
        (book.educationStageId === currentBook.educationStageId) &&
        (book.categoryName === "كتب")
      );

      const newBooks2 = allBooks?.filter(book =>
        (book.levelId === currentBook.levelId) &&
        (book.educationStageId === currentBook.educationStageId) &&
        (book.categoryName === "ملازم")
      );

      setSameYearBooks(newBooks);
      setSameYearMalazem(newBooks2);
    }
  };

  const pageVariants = {
    initial: {
      opacity: 0,
      y: "+100vh",
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.div
      className="bg-white min-h-screen p-4 flex flex-col items-center "
      initial="initial"
      animate="animate"
      variants={pageVariants}
    >
      {showToast && (
        <Toast
          text="تمت الإضافة إلى السلة بنجاح"
          icon={faCartPlus}
          visible={showToast}
        />
      )}
      {showModal && <Modal setsShowModal={setsShowModal} />}
      <div className="flex items-center justify-between mb-3 w-full max-w-screen-lg">
        <Link to="/">
          <img src={logo} alt="Logo" className="w-32 h-auto" />
        </Link>    
        <div className="flex items-center relative">
          <Link to="/cart" className="text-3xl mr-4 text-basic">
            <MdOutlineShoppingCart icon={faCartPlus} />
            <motion.span
              className="absolute top-3 left-6 text-base bg-gray-300 text-black w-[20px] text-center rounded-full"
              animate={cartControls}
            >
              {cartNumber}
            </motion.span>
          </Link>
          {isAuthenticated() ? (
            ""
          ) : (
            <Link to="/login" className="text-xl mr-4 text-gray-500">
              <FaSignInAlt />
            </Link>
          )}
        </div>
      </div>
      {loading ?
        <div role="status" className="absolute top-40 left-[50%]">
          <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin fill-yellow-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          <span class="sr-only">تحميل...</span>
        </div>
        : <div className="w-full max-w-screen-lg grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="flex flex-col items-center lg:items-start mt-9">
            <motion.img
              src={bookurl}
              alt="The Conquest of Happiness"
              className="md:w-1/2 w-full h-auto object-fill rounded-12px"
              style={{ maxWidth: "50%", margin: "0 auto" }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 1.1, rotate: 10 }}
            />
          </div>

          <h2 className="text-2xl font-extrabold mb-4 text-center">{currentBook.title}</h2>
          <div className="flex flex-col justify-start">
            <div className="mb-6 px-4 md:px-0">
              <h3 className="text-2xl font-bold text-gray-700 mb-2">تفاصيل الكتاب:</h3>
              {/* <p className="text-lg text-gray-700 lg:text-right mt-4">
                <span className="font-bold">الاسم</span> : {currentBook.title}
              </p> */}
              <p className="text-lg text-gray-700 lg:text-right mt-4">
                {/* <span className="font-bold">تفاصيل</span> :  */}
                {currentBook.bookDescription}
              </p>
              {/* <p className="text-lg text-gray-700 lg:text-right mt-4">
                <span className="font-bold">كتاب</span> : {currentBook?.authorName} {currentBook?.subjectName} {currentBook?.levelName} {currentBook?.educationStageName} {currentBook?.isForFirstTerm ? "الترم الأول" : " الترم الثاني"}
              </p> */}
              <p className="text-lg text-gray-700 lg:text-right mt-4">
              {currentBook.categoryName != "ملازم" ? <><span className="font-bold">السعر</span> : <span className="line-through text-red-500"> {currentBook.price} جنيه </span> <span className=" mx-3"> {(currentBook.price* 0.9).toFixed(2)} جنيه </span></>
              :<><span className="font-bold">السعر</span> : <span className="mx-3"> {currentBook.price} جنيه </span></>}
              </p>
              <p className="text-base text-red-500 mb-2">
                {(currentBook.quantityAvailable <1)&&"نفذت الكمية"}
              </p>
            </div>
            <div className="flex justify-center lg:justify-start lg:mt-[40px]">
              <motion.button
                onClick={handleAddToCart}
                className="w-full mr-2 ml-4 py-[6px] px-4 border border-transparent rounded-lg shadow-sm mb-1 font-bold text-md text-white bg-basic hover:bg-basic active:outline-none active:ring-2 active:ring-offset-2 active:ring-[#e8b914]"
                whileTap={{ scale: 0.95 }}
              >
                اضافة الي السلة
              </motion.button>
              <motion.button
                className="flex justify-center items-center mr-4 ml-4 mb-1 bg-[#dbdbdb6b] rounded-full px-2 py-2"
                onClick={handleLikeClick}
                whileTap={{ scale: 1.2 }}
                animate={currentBook.favourite ? { scale: [1, 1.5, 1], transition: { duration: 0.6 } } : {}}
              >
                <FontAwesomeIcon
                  icon={faHeart}
                  className={`text-3xl ${currentBook.favourite ? "text-basic" : "text-[#8b8b8b8f]"}`}
                />
              </motion.button>
            </div>
          </div>
          {/* suggestions */}
          <hr className="mt-8" />
          <div>
            <div className="mb-8" >
              <div className="flex flex-row items-center justify-between">
                <h2 className="mb-4 text-xl font-bold">كتب متعلقة   </h2>
              </div>
              <div className="flex overflow-x-auto pb-4 scrollbar-hide ">
                <div className="flex overflow-x-auto overflow-y-hidden pb-4 scrollbar-hide">
                  {sameYearBooks
                    ? sameYearBooks.map((book) => (
                      <BookCard
                        key={book.bookId}
                        book={book}
                        handleSelectBook={handleSelectBook}
                      />
                    ))
                    : Array.from({ length: 6 }).map((_, index) => (
                      <GrayCard key={index} />
                    ))}
                </div>
              </div>
            </div>

            <div className="mb-8" >
              <div className="flex flex-row items-center justify-between">
                <h2 className="mb-4 text-xl font-bold">ملازم متعلقة   </h2>
              </div>
              <div className="flex overflow-x-auto pb-4 scrollbar-hide ">
                <div className="flex overflow-x-auto overflow-y-hidden pb-4 scrollbar-hide">
                  {currentBook && (sameYearMalazem
                    ? sameYearMalazem.map((book) => (
                      <BookCard
                        key={book.bookId}
                        book={book}
                        handleSelectBook={handleSelectBook}
                      />
                    ))
                    : Array.from({ length: 6 }).map((_, index) => (
                      <GrayCard key={index} />
                    )))}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </motion.div>
  );
};

const BookCard = ({ book, handleSelectBook }) => {
  const [ref, isInView] = useInView();

  return (
    <motion.div
      ref={ref}
      className="mr-4"
      onClick={() => {
        handleSelectBook(book);
      }}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 50 }}
      transition={{ duration: 0.5 }}
    >
      <Card
        imageUrl={book.image}
        title={book.title}
        price={`${book.price} جنيه`}
        bookId={book.bookId}
        favourite={book.favourite}
        description={book.bookDescription}
        levelName={book.levelName}
        educationStageName={book.educationStageName}
        isForFirstTerm={book.isForFirstTerm}
        id={book.bookId}
        quan = {book.quantityAvailable}
      />
    </motion.div>
  );
};


const Card = ({
  imageUrl,
  title,
  price,
  favourite,
  isForFirstTerm,
  educationStageName,
  levelName,
  description,
  quan
}) => {
  const bookurl = "https://mkanaak-001-site4.htempurl.com/" + imageUrl.replace(/\\/g, "/");
  return (
    <motion.div
      className="flex flex-col items-center p-2 border rounded-lg shadow-sm cursor-auto w-[170px] hover:cursor-pointer"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <img
        loading="lazy"
        src={bookurl}
        alt={title}
        className="w-full object-cover rounded-md mb-2"
      />
      <h3 className="text-lg font-bold ">{title}</h3>
      <p className=" text-base text-gray-700 mb-1">{description}</p>
      <p className="text-base text-gray-700 mb-2">
        {levelName} {educationStageName}{" "}
        {isForFirstTerm ? "الترم الأول" : " الترم الثاني"}
      </p>
      <p className="text-base text-red-500 mb-2">
          {(quan<1)&&"نفذت الكمية"}
        </p>
      <div className="flex justify-between w-full">
        <button>
          <FontAwesomeIcon
            icon={faHeart}
            className={` text-xl ${favourite ? " text-basic" : "text-gray-400"
              }`}
          />
        </button>
        <MdShoppingCart className="text-basic text-xl" />

      </div>

    </motion.div>
  );
};

const GrayCard = () => {
  return (
    <div className="min-w-[150px] max-w-[150px] mr-4">
      <div className="w-full h-32 bg-gray-300 rounded-lg mb-2"></div>
      <h3 className="text-center text-gray-400">تحميل...</h3>
    </div>
  );
};

export default BookDetail;
