import React, { useEffect, useState , useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdShoppingCart } from "react-icons/md";
import {
  faSearch,
  faHeart,
  faCartPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import logo from "../Assets/Images/Mkanak-Logo-small.png";
import { FaSignInAlt } from "react-icons/fa";
import Fuse from "fuse.js";
import Navbar from "./Details/Navbar";
import CustomSelect from "../components/CustomSelect";
import { isAuthenticated } from "../utils/auth";
import Floatingnavbar from "./Details/Floatingnavbar";
import { MdOutlineShoppingCart } from "react-icons/md";
import { debounce } from 'lodash';

const debouncedHandleSearch = debounce((term, fuse, allBooks, setFilteredBooks) => {
  if (term) {
    const terms = term.split(" ").filter((t) => t);
    const results = allBooks.filter((book) =>
      terms.every((t) =>
        fuse
          .search(t)
          .map((result) => result.item)
          .includes(book)
      )
    );
    setFilteredBooks(results);
  } else {
    setFilteredBooks(allBooks);
  }
}, 600);

const Search = ({
  setCurrentBook,
  educationStage,
  level,
  subject,
  category,
  author,
  allBooks,
  cartNumber,
  refreshData,
}) => {
  // states
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedAuthor, setSelectedAuthor] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [filteredBooks, setFilteredBooks] = useState(allBooks);
  const [searchTerm, setSearchTerm] = useState("");

  // useEffect
  useEffect(() => {
    filterBooks();
  }, [
    selectedStage,
    selectedLevel,
    selectedAuthor,
    selectedSubject,
    selectedCategory,
  ]);

  useEffect(() => {
    setFilteredBooks(allBooks);
  }, [refreshData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  //const
  const fuse = new Fuse(allBooks, {
    includeScore: true,
    threshold: 0.3,
    keys: [
      { name: "bookDescription", weight: 0.4 },
      { name: "title", weight: 0.3 },
      { name: "categoryName", weight: 0.1 },
      { name: "subjectName", weight: 0.1 },
      { name: "levelName", weight: 0.1 },
      { name: "educationStageName", weight: 0.1 },
      { name: "authorName", weight: 0.1 },
    ],
  });

  const handleSelectBook = (book) => {
    setCurrentBook(book);
  };

  const filterBooks = () => {
    if (allBooks) {
      const newBooks = allBooks?.filter(
        (book) =>
          (selectedCategory === "" || book.categoryId === selectedCategory) &&
          (selectedLevel === "" || book.levelId === selectedLevel) &&
          (selectedAuthor === "" || book.authorId === selectedAuthor) &&
          (selectedSubject === "" || book.subjectId === selectedSubject) &&
          (selectedStage === "" || book.educationStageId === selectedStage)
      );
      setFilteredBooks(newBooks);
    }
  };

  const handleSearch = useCallback((event) => {
    const term = event.target.value;
    setSearchTerm(term);
    debouncedHandleSearch(term, fuse, allBooks, setFilteredBooks);
  }, [fuse, allBooks, setFilteredBooks]);

  return (
    <div className="bg-white min-h-screen p-4 mb-20">
      {/* Header */}
      <div className="flex items-center justify-between mb-3">
        <img src={logo} alt="Logo" className="w-32 h-auto" />
        <div className="flex items-center relative">
          <Link to="/cart" className=" text-3xl mr-4 text-basic">
            <MdOutlineShoppingCart icon={faCartPlus} />{" "}
            <span className=" absolute top-3 left-6 text-base bg-gray-300 text-black  w-[20px] text-center rounded-full  ">
              {cartNumber}
            </span>
          </Link>
          {isAuthenticated() ? (
            ""
          ) : (
            <Link to="/login" className="text-xl mr-4 text-gray-500">
              <FaSignInAlt />
            </Link>
          )}
        </div>
      </div>

      {/* Search Bar */}
      <div className="mb-4">
        <div className="relative max-w-lg mx-auto">
          <input
            value={searchTerm}
            onChange={handleSearch}
            type="text"
            placeholder="ابحث هنا "
            className="w-full py-3 pl-10 pr-4 border border-gray-300 rounded-full focus:ring-2 focus:ring-offset-2 ring-[#e8b914]"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute top-1/2 left-4 transform -translate-y-1/2 text-gray-500 "
          />
        </div>
      </div>

      {/* filter section */}
      <div className="flex w-full flex-wrap mb-2 text-md scrollbar-hide custom-scroll ">
        <div className="mr-1 mb-2 ">
          <CustomSelect
            data={category}
            setoption={setSelectedCategory}
            refreshData={refreshData}
          />
        </div>
        <div className="mr-1 mb-2">
          <CustomSelect
            data={educationStage}
            setoption={setSelectedStage}
            refreshData={refreshData}
          />
        </div>
        <div className="mr-1 mb-2">
          <CustomSelect
            data={level}
            setoption={setSelectedLevel}
            refreshData={refreshData}
          />
        </div>
        <div className="mr-1 mb-2">
          <CustomSelect
            data={author}
            setoption={setSelectedAuthor}
            refreshData={refreshData}
          />
        </div>
        <div className="mr-1 mb-2">
          <CustomSelect
            data={subject}
            setoption={setSelectedSubject}
            refreshData={refreshData}
          />
        </div>
      </div>

      {/* ggggoooooolllllddddd  */}
      {/* select educationStage */}
      {/* <div className="flex mb-2 text-sm overflow-x-auto scrollbar-hide ">
          {educationStage?.map((option) => (
            <button
              key={option?.value}
              className={`px-4 py-[2px] bg-gray-200 rounded-full whitespace-nowrap mr-4 ${selectedStage == (option?.value) ? "bg-yellow-400" : "bg-gray-200"}`}
              onClick={() => handleSelectStage(option)}
            >
              {option?.label}
            </button>
          ))}
        </div> */}

      {/* select level */}
      {/* <div className="flex mb-2 text-sm overflow-x-auto scrollbar-hide">
          {level?.map((option) => (
            <button
              key={option?.value}
              className={`px-4 py-[2px] bg-gray-200 rounded-full whitespace-nowrap mr-4 ${selectedLevel == (option?.value) ? "bg-yellow-400 text-gray-100 " : "bg-gray-200"}`}
              onClick={() => handleSelectLevel(option)}
            >
              {option?.label}
            </button>
          ))}
        </div> */}

      {/* select category */}
      {/* <div className="flex mb-2 text-sm overflow-x-auto scrollbar-hide">
          {category?.map((option) => (
            <button
              key={option?.value}
              className={`px-4 py-[2px] bg-gray-200 rounded-full whitespace-nowrap mr-4 ${selectedCategory == (option?.value) ? "bg-yellow-400" : "bg-gray-200"}`}
              onClick={() => handleSelectCategory(option)}
            >
              {option?.label}
            </button>
          ))}
        </div> */}

      {/* select author */}
      {/* <div className="flex mb-2 text-sm overflow-x-auto scrollbar-hide">
          {author?.map((option) => (
            <button
              key={option?.value}
              className={`px-4 py-[2px] bg-gray-200 rounded-full whitespace-nowrap mr-4 ${selectedAuthor == (option?.value) ? "bg-yellow-400" : "bg-gray-200"}`}
              onClick={() => handleSelectAuthor(option)}
            >
              {option?.label}
            </button>
          ))}
        </div> */}

      {/* select subject */}
      {/* <div className="flex mb-2 text-sm overflow-x-auto scrollbar-hide">
          {subject?.map((option) => (
            <button
              key={option?.value}
              className={`px-4 py-[2px] bg-gray-200 rounded-full whitespace-nowrap mr-4 ${selectedSubject == (option?.value) ? "bg-yellow-400" : "bg-gray-200"}`}
              onClick={() => handleSelectSubject(option)}
            >
              {option?.label}
            </button>
          ))}
        </div> */}

      {/* cards */}
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 relative">
        {filteredBooks ? (
          !filteredBooks.length ? (
            <div className=" absolute top-20 left-[25%]">
              {" "}
              <p className="text-2xl text-gray-500 font-extrabold  ">
                Try another words
              </p>{" "}
            </div>
          ) : (
            filteredBooks?.map((book) => (
              <div
                onClick={() => {
                  handleSelectBook(book);
                }}
              >
                <BookCard
                  imageUrl={book.image}
                  title={book.title}
                  price={`${book.price} جنيه`}
                  bookId={book.bookId}
                  favourite={book.favourite}
                  description={book.bookDescription}
                  levelName={book.levelName}
                  educationStageName={book.educationStageName}
                  isForFirstTerm={book.isForFirstTerm}
                  id={book.bookId}
                  quan = {book.quantityAvailable}
                />
              </div>
            ))
          )
        ) : (
          <div role="status" className="absolute top-40 left-[50%]">
            <svg
              aria-hidden="true"
              class="w-8 h-8 text-gray-200 animate-spin fill-yellow-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">تحميل...</span>
          </div>
        )}
      </div>

      {/* Bottom Navigation */}
      <Floatingnavbar selectLink="3" />
    </div>
  );
};

const BookCard = ({
  imageUrl,
  title,
  price,
  favourite,
  isForFirstTerm,
  educationStageName,
  levelName,
  description,
  id,
  quan
}) => {
  const bookurl =
    "https://mkanaak-001-site4.htempurl.com/" + imageUrl.replace(/\\/g, "/");
  return (
    <Link to={`/bookdetails/${id}`}>
      <div className="flex flex-col items-center p-2 border rounded-lg shadow-sm cursor-auto hover:opacity-70 hover:cursor-pointer">
        <img
          loading="lazy"
          src={bookurl}
          alt={title}
          className="w-full object-cover rounded-md mb-2"
        />
        <h3 className="text-lg font-semibold ">{title}</h3>
        {/* <p className="text-sm text-gray-600 mb-1">{price}</p> */}
        <p className=" text-base text-gray-700 mb-1">{description}</p>
        {/* <p className="text-base text-gray-700 mb-2">
          {levelName} {educationStageName}{" "}
          {isForFirstTerm ? "الترم الأول" : " الترم الثاني"}
        </p> */}
        <p className="text-base text-red-500 mb-2">
          {(quan<1)&&"نفذت الكمية"}
        </p>
        <div className="flex justify-between w-full">
          <button>
            <FontAwesomeIcon
              icon={faHeart}
              className={`text-xl ${
                favourite ? " text-basic" : "text-gray-400"
              }`}
            />
          </button>
          <MdShoppingCart className="text-basic text-xl" />
        </div>
      </div>
    </Link>
  );
};

export default Search;
